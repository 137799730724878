
export default class Traffic {
  constructor({ context } = {}) {
    this.context = context;
  }

  // Get or create a global _expDataLayer object.
  _log(command) {
    const { context: w } = this;
    if (!w._expDataLayer) w._expDataLayer = [];
    w._expDataLayer.push(command);
  }

  /**
   * @public traffic.addEvent - Logs an event with the given metadata.
   *
   * For more information, see: https://confluence.godaddy.com/pages/viewpage.action?pageId=103207992
   *
   * @param {String} eid event eId (e.g. `gce.sales.hosting.profile.login.update` (required)
   * @param {String} type event type. (e.g. `click`, `hover`, `touch`, `custom`) (required)
   * @param {String} version schema version. Defaults to v1 if nothing is provided
   */
  addEvent(eid, type = 'consent', version = 'v1') {
    this._log({
      data: { eid, type },
      schema: 'add_event',
      version
    });
  }

  /**
   * @public traffic.addImpression - Logs an impression with the given ID.
   *
   * For more information, see: https://confluence.godaddy.com/pages/viewpage.action?pageId=103207987
   *
   * @param {String} eid  eId (e.g. `gce.sales.hosting.profile.login.update` (required)
   *                      See https://confluence.godaddy.com/display/CKPT/Event+Naming+and+Formats
   * @param {String} version schema version. Defaults to v1 if nothing is provided
   * @returns {undefined}
   */
  addImpression(eid, version = 'v1') {
    this._log({
      data: { eid },
      schema: 'add_impression',
      version
    });
  }
}
