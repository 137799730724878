/* eslint-disable max-statements */
// TODO: re-enable implicit when business allows
import ImplicitNoticeBanner from './components/banners/implicit-notice';
import ExplicitBanner from './components/banners/explicit';

import * as allEids from './content/eids.js';
import { viewUtag } from './services/utag';
import ConsentApiClient from './services/consent';
import Traffic from './services/traffic';

import Cookie from './cookies/cookie.js';
import OptOutMulti from './cookies/optoutmulti.js';
import PwInteraction from './cookies/pwinteraction.js';

/**
 * Set of known paths on which the banners should not be displayed.
 * We have these exceptions to avoid infinite link loops where "More Info"
 * links to the same page, which would then show the banner, etc.
 */
const privacyPaths = [
  '/legal',      // Verified on: godaddy.com, hosteurope.de, mediatemple.net, and poynt.com
  '/agreements', // Verified on: godaddy.com and wildwestdomains.com
  '/privacy',    // Verified on: wildwestdomains.com, and poynt.com
  '/terms',      // Verified on: 123-reg.co.uk, hosteurope.de, and poynt.com
  '/cookie'      // Verified on: sucuri.net, madewithover.com, sellbrite.com
];

/**
 * Privacy check for pages
 *
 * @param {string} pathName Path name
 * @returns {boolean} Value indicating if the `pathName` provided
 *                    is a privacy policy page
 * @private
 */
function privacyPageCheck(pathName) {
  return privacyPaths.includes(pathName.toLowerCase());
}

/**
 * Visitor to the page that we are acquiring consent from
 */
export default class Visitor {
  /**
   * @param {Object} opts Options
   * @param {URL} opts.url WHATWG URL for the current page
   * @param {optOutCats} opts.optOutCats Opt-out categories for active plid
   */
  constructor({ url, optOutCats, context }) {
    this.url = url;
    this.optOutCats = optOutCats;
    this.context = context;

    // Visitor related cookies: OPTOUTMULTI, pwinteraction, & info_idp
    const domain = Cookie.rootDomainFor(url);
    this.pwinteraction = new PwInteraction({ domain });
    this.infoIdp = new Cookie({ name: 'info_idp', domain });
    this.market = new Cookie({ name: 'market', domain });
    this.optoutmulti = new OptOutMulti({
      context,
      domain,
      optOutCats
    });

    // Traffic client for logging as needed
    this.tcc = new Traffic({ context });
  }

  /**
   * Re-reads the optout values from cookie sources
   * @public
   */
  reloadOptOut() {
    this.optoutmulti.read();
  }

  /**
   * Starts a visit by preparing from relevant consent state (cookies, etc.).
   *
   * @returns {Object} React component & associated EIDs to render
   * @public
   */
  startVisit() {
    this.getConsentType();
    this.prepareOptOutMulti();
    return this.getConsentBanner();
  }

  /**
   * Returns the regulatory consent necessary based on the
   * _policy cookie value.
   *
   * @returns {string} Governing regulatory consent for the page.
   */
  getConsentType() {
    if (!this.consentType) {
      //
      // Determine consentType ['implicit', 'explicit'] from
      // the value of the _policy cookie
      //
      const policy = new Cookie({ name: '_policy' });
      try {
        const { tracking_market: marketConsent } = policy.readJson();
        this.consentType = marketConsent || 'explicit';
      } catch (err) {
        // Tracking market was not able to be collected.
        // Default to explicit experience.
        this.consentType = 'explicit';
      }
    }

    return this.consentType;
  }

  /**
   * Determine if a visitor has consented to a specific category
   *
   * @param {string} category Consent category name
   * @returns {boolean|undefined} Returns boolean if category is opted in, undefined if not present
   * @public
   */
  getConsentForCat(category) {
    const consents = this.optoutmulti.consents || {};
    // Return undefined if the property is not present in the consents object
    if (!Object.hasOwnProperty.call(consents, category)) return;
    return consents[category];
  }

  /**
   * Repairs any malformed values that may be set for OPTOUTMULTI
   * cookie and then sets it to the initial value for the relevant
   * regulatory consent of the market in which the page is running.
   *
   * * implict: default opt-in
   * * explicit: default opt-out
   */
  prepareOptOutMulti() {
    // Ensure OPTOUTMULTI is not corrupted if it is already set.
    this.optoutmulti.repair();

    // TODO: re-enable implicit when business allows
    const action = (this.consentType === 'none' || this.consentType === 'implicit' || this.consentType === 'implicit_notice')
      ? 'enable'
      : 'disable';

    if (!this.optoutmulti.value) {
      this.optoutmulti.setOptOutAction(action);
    }

    if (this.consentType !== 'explicit' || this.getPrivateLabelId() !== 1) {
      // Immediately track until opt-out is acquired
      viewUtag(this.optoutmulti.consents);
    }
  }

  /**
   * Determines the correct banner component & associated EIDs
   *
   * @returns {Object} React component & associated EIDs to render
   * @public
   */
  getConsentBanner() {
    const isPrivacyPage = privacyPageCheck(this.url.pathname);

    //
    // If the customer hasn't interacted with the consent banners
    // or was before soft / hard GDPR launch date. Present banners.
    //
    if (!this.pwinteraction.isRecent(this.market.read())) {
      let eids;
      let Banner;

      if (this.consentType === 'explicit') {
        eids = allEids.explicitBanner;
        Banner = ExplicitBanner;

        // As Cookie module loads with consent type explicit send event to traffic client
        this.tcc.addImpression(eids.impression);
      } else if (this.consentType === 'implicit_notice') {
        eids = allEids.implicitNoticeBanner;
        Banner = ImplicitNoticeBanner;

        // As Cookie module loads send event to traffic client
        this.tcc.addImpression(eids.impression);
      }

      if (Banner && isPrivacyPage === false) {
        return { Component: Banner, eids };
      }
    } else if (this.getPrivateLabelId() !== 1) {
      viewUtag(this.optoutmulti.consents);
    }

    return null;
  }

  /**
   * Records a consent interaction for this visitor
   *
   * @param {string}  [eid]        Optional Traffic EID to record
   * @param {boolean} [utag=false] If true, loads Tealium
   * @param {Object}  [consents]   If set attempts to record updated
   *                               consents in our Consent API
   */
  async recordInteraction({
    eid,
    utag = false,
    consents
  }) {
    // Set last interaction time to now
    this.pwinteraction.record();

    if (consents) {
      this.optoutmulti.setConsents(consents);

      // eslint-disable-next-line no-unused-vars
      const consentApi = this.createConsentClient();
      try {
        await consentApi.put(this.optoutmulti.consents);
      // eslint-disable-next-line no-empty
      } catch (e) {}

      const value = this.optoutmulti.toStringValue();
      this.optoutmulti.write({ value });

      const { context } = this;
      const utagData = context.utag_data || (context.utag_data = {});
      utagData['cp.OPTOUTMULTI'] = value;
    }

    if (utag && this.getPrivateLabelId() !== 1) viewUtag(this.optoutmulti.consents);
    if (eid) this.tcc.addEvent(eid);
  }

  /**
   * Lazy creation of a ConsentApiClient, this.consentApi,
   * to update consent for this visitor
   *
   * @returns {ConsentApiClient} Pre-configured API client for this visitor
   */
  createConsentClient() {
    if (!this.consentApi) {
      this.consentApi = new ConsentApiClient({ visitor: this });
    }

    return this.consentApi;
  }

  /**
   * Returns the language for the Market cookie associated
   * with this visitor
   *
   * @returns {string} IETF BCP 47 language tag
   */
  getLanguage() {
    const market = this.market.read();
    // Return the first component of the market as the language
    return (typeof market === 'string' ? market.split('-')[0] : 'en').toLowerCase();
  }

  /**
   * Return privateLabelId from window.ux.data
   * @returns {number} privateLabelId
   */

  getPrivateLabelId() {
    return Number(window.ux?.data?.privateLabelId) || 1;
  }

}
