import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@ux/button';
import Banner from './banner.js';

/**
 * Create implicit banner view
 *
 * @param {Visitor} visitor Page viewer for which consents are updating
 * @param {object} eids Object of events name to send events to traffic client
 * @param {object} i18n The localization strings object
 * @param {function} onClose onClose banner module open privacy manager module
 * @param {string} privacyPolicyUrl The URL to get more details about privacy policy
 * @returns {Component} The implicit banner module
 * @public
 */
export default function ImplicitNoticeBanner({ visitor, eids, i18n, onClose, privacyPolicyUrl }) {
  const [visible, setVisible] = useState(true);

  const onClickAccept = () => {
    visitor.recordInteraction({
      eid: eids.dismiss,
      utag: true,
      consents: 'enable'
    });

    setVisible(false);
    onClose({ action: 'accept' });
  };

  const onClickManageSettings = () => {
    visitor.recordInteraction({ eid: eids.manageSettings });

    setVisible(false);
    onClose({ action: 'manage' });
  };

  const renderChoices = () => {
    return <>
      <Button
        id='pw_manage'
        onClick={ onClickManageSettings }
        className='btn-link ux-btn-set-item'
        design='link'
        type='button'
        text={ i18n['banners.manageSettings'] } />
    </>;
  };

  const renderLearnMore = () => {
    return <>
      {' '}
      <Button id='pw_info'
        href={ privacyPolicyUrl }
        external={ true }
        design='inline'
        size='small'
        text={ i18n['banners.privacyPolicy'] }
        data-eid={ eids.moreInfo } />
    </>;
  };

  const renderCrossIcon = () => {
    return <Button
      onClick={ onClickAccept }
      aria-label='Close modal'
      className='modal-close'
      text='x'/>;
  };

  return <Banner
    title={ i18n['banners.title'] }
    notice={ i18n['banners.implicit_notice.description'] }
    visible={ visible }
    renderChoices={ renderChoices }
    renderCrossIcon={ renderCrossIcon }
    renderLearnMore={ renderLearnMore }  />;
}

ImplicitNoticeBanner.propTypes = {
  visitor: PropTypes.object.isRequired,
  eids: PropTypes.objectOf(PropTypes.string).isRequired,
  i18n: PropTypes.objectOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  privacyPolicyUrl: PropTypes.string.isRequired
};
