import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from '@ux/button';
import Toggle from '@ux/toggle';
import Modal from '@ux/modal';
import SiblingSet from '@ux/sibling-set';

import * as eids from '../content/eids.js';

/**
 * Modal-based view responsible for managing consents more granularly
 * for each opt-out category available to the visitor
 *
 * @param {Visitor} visitor Page viewer for which consents are updating
 * @param {string} consentType The value of the consent Type
 * @param {object} i18n The localization strings object
 * @param {boolean} open Flag to show / hide Modal
 * @param {function} setOpen To show / hide Modal based on flag state
 * @returns {View} The privacy manager module
 * @public
 */
export default function PrivacyManager({ visitor, i18n, open, setOpen }) {
  // As Privacy manager loads send event to traffic client
  visitor.tcc.addImpression(eids.privacyManager.impression);

  const checkedEssential = true;
  const { consentType, optOutCats } = visitor;

  const buildStateFromCookies = useCallback(() => {
    // Re-read cookies first when calculating state from cookies
    visitor.reloadOptOut();
    return Object.keys(optOutCats).reduce((acc, category) => {
      const storedValue = visitor.getConsentForCat(category);
      // The value stored in cookie is for "optout" so must be inverted for use in the checkboxes.
      acc[category] = typeof storedValue !== 'undefined' ? !storedValue : consentType !== 'explicit';
      return acc;
    }, {});
  }, [optOutCats, consentType]);

  // No need to calculate initial state on every render
  const initialState = useMemo(buildStateFromCookies, []);
  const [categoryStates, setCategoryStates] = useState(initialState);

  // eslint-disable-next-line no-unused-vars
  const onSubmit = useCallback(evt => {
    evt.preventDefault();

    visitor.recordInteraction({
      eid: eids.privacyManager.save,
      utag: true,
      //
      // Uses Tealium {0 => true , 1 => false} format
      //
      consents: Object.keys(categoryStates).reduce((acc, cat) => {
        const value = Boolean(categoryStates[cat]);
        acc[cat] = value ? 0 : 1;
        return acc;
      }, {})
    });

    setOpen(false);
  }, [categoryStates]);

  const onClickClose = () => {
    visitor.recordInteraction({ eid: eids.privacyManager.dismiss });
    setOpen(false);
  };

  const setChecked = (key, newValue) => {
    setCategoryStates({
      ...categoryStates,
      [key]: newValue
    });
  };

  return (
    <Modal
      open={ open }
      id='privacy_manager_modal'
      portalElem={ document.getElementById('gtm_privacy')?.shadowRoot }
      title={ i18n['privacyManager.title'] }
      onClose={ onClickClose }>
      <form onSubmit={ evt => onSubmit(evt, categoryStates) }>
        <div id='privacy_view'>
          <p className='pm_description'>{ i18n['privacyManager.description'] }</p>
          <h4 className='headline-primary'> { i18n['privacyManager.category'] } </h4>
          {
            Object.keys(optOutCats).map(key => {
              return (
                <div className='pm_option' key={ key }>
                  <div className='pm_option-title'>
                    <strong> { i18n['privacyManager.' + key] } </strong>
                  </div>
                  <div className='pm_option-desc' key={ `${key}Desc` }>
                    <p> { i18n['privacyManager.' + key + 'Text'] } </p>
                  </div>
                  <div className='pm_toggle'>
                    <Toggle id={ key } key={ `${key}Toggle` } checked={ categoryStates[key] }
                      onChange={ () => setChecked(key, !categoryStates[key]) } />
                  </div>
                </div>
              );
            })
          }
          <div className='pm_option'>
            <div className='pm_option-title'>
              <strong> { i18n['privacyManager.essential'] } </strong>
            </div>
            <div className='pm_option-desc'>
              <p> { i18n['privacyManager.essentialText'] } </p>
            </div>
            <div className='pm_toggle'>
              <Toggle
                key='privacy_view_essential'
                checked={ checkedEssential }
                label={ i18n['privacyManager.on'] }
                disabled />
            </div>
          </div>
        </div>
        <SiblingSet gap='md'>
          <Button
            id='privacy_view_confirm'
            className='ux-btn-set-item'
            design='primary'
            type='submit'
            text={ i18n['privacyManager.save'] }/>
          <Button
            id='privacy_view_cancel'
            className='ux-btn'
            design='secondary'
            type='button'
            onClick={ onClickClose }
            text={ i18n['banners.dismiss'] }/>
        </SiblingSet>
      </form>
    </Modal>
  );
}

PrivacyManager.propTypes = {
  visitor: PropTypes.object.isRequired,
  i18n: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};
