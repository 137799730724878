/**
 * Fetch-based API client for Consent API v1
 */
export default class ConsentApiClient {
  /**
   * @param {Visitor} visitor Page viewer for which consents are updating
   */
  constructor({ apiHost, visitor }) {
    this.apiHost = apiHost;
    this.visitor = visitor;
    this.endpoint = this.endpointFor(visitor);
  }

  /**
   * @typedef {Object} GetResult
   * @prop {boolean} ok Whether the fetch was successful
   * @prop {string} etag The HTTP Etag header of the response
   * @prop {any} result The parsed JSON result body
   */
  /**
   * Attempts to get the consent values for the visitor associated
   * with this instance.
   *
   * @type {Promise<GetResult>} returns { ok, etag, result } from fetch response
   */
  async get() {
    //
    // Remark (crobbins): throw here instead?
    // TODO (crobbins): update to Consent API V2
    //
    const { endpoint } = this;
    if (!this.endpoint) return;

    //
    // Sample consents:
    //
    // {
    //   "analytics": "TRUE",
    //   "directMail": "FALSE",
    //   "emailAccountSummaryAndUpdates": "TRUE",
    //   "marketingAndAdvertising": "TRUE",
    //   "smsPromotional": "FALSE",
    //   "support": "TRUE",
    //   "voicePromotional": "FALSE"
    // }
    //
    // Additional API documentation:
    // https://confluence.godaddy.com/display/PLATAPI/Updating+Multiple+Consent+values
    //

    const res = await fetch(endpoint, { credentials: 'include' });
    const result = await res.json();

    return {
      ok: res.ok,
      etag: res.headers.get('etag'),
      result
    };
  }

  /**
   * Attempts to merge new `values` with any existing the consent values
   * for the visitor associated with this instance.
   *
   * @param {Object} values New consent values acquired from the visitor
   * @returns {Promise<Response>} returns Fetch Response object for the PUT request
   */
  async put(values) {
    const { result: consents, etag, ok } = await this.get() || {};
    if (!ok) return;

    //
    // TODO (crobbins): update to Consent API V2
    //
    const merged = this.mergeConsents(consents, values);
    return fetch(this.endpoint, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'If-Match': etag
      },
      credentials: 'include',
      mode: 'cors',
      body: merged
    });

  }

  /**
   * Merge consent preferences
   *
   * @param {string} consents The existing consent settings
   * @param {Object} values New consent values acquired from the visitor
   * @returns {string} Merged consents, stringified
   * @private
   */
  mergeConsents(consents, values) {
    if (!consents || !values) return;

    const newConsents = Object.keys(values).reduce((acc, cat) => {
      acc[cat] = values[cat] === 0 ? 'TRUE' : 'FALSE';
      return acc;
    }, {});

    function valueFor(nk, ck) {
      if (!ck) ck = nk;

      const value = newConsents[nk] || consents[ck];
      return value === true || value === 'TRUE'
        ? 'TRUE'
        : 'FALSE';
    }

    // Modified consent settings for options provided by consent manager
    const updated = {
      marketingAndAdvertising: valueFor('advertising', 'marketingAndAdvertising'),
      analytics: valueFor('analytics'),
      support: valueFor('support')
    };

    // Merge `updated` values into existing `consents`.
    return JSON.stringify({
      ...consents,
      ...updated
    });
  }

  /**
   * Builds the endpoint for the specified visitor
   *
   * @param {Visitor} visitor Page viewer for which consents are updating
   * @returns {string} The API string
   * @private
   */
  endpointFor(visitor) {
    const { info_cid: cid } = visitor.infoIdp.readJson();
    if (!cid) return '';

    let apiHost = this.apiHost;
    if (!apiHost) {
      const { hostname } = visitor.url;
      const [prefix = ''] = hostname.match(/(dev-|test-|sgt-)/) || [];
      const [, , domain] = hostname.match(/(dev-|test-|sgt-)?(godaddy.com)/) || [];

      apiHost = !domain
        ? `${prefix}secureserver.net`
        : `${prefix}${domain}`;
    }

    return `https://api.${apiHost}/v1/customers/${cid}/consent`;
  }
}
