/* eslint-disable no-process-env */

let UTAG_URL;
let UTAG_DEFAULT_RESELLER_URL;
if (process.env.NODE_ENV === 'production') {
  // @TODO using process.env is confusing here when we ignore it in development mode
  UTAG_URL = process.env.UTAG_URL;
  UTAG_DEFAULT_RESELLER_URL = process.env.UTAG_DEFAULT_RESELLER_URL;
} else {
  UTAG_URL = 'https://tags.tiqcdn.com/utag/godaddy/dev-server/dev/utag.js';
  // No DEV url available as of August 2022, assign production URL
  UTAG_DEFAULT_RESELLER_URL = 'https://tags.tiqcdn.com/utag/gpl/main/dev/utag.js';
}

//
// TODO (crobbins): remove this once @godaddy/markets
// has been updated to be more tree shake friendly
//
export const RTL_MARKETS = ['ar'];

//
// The following values are specific to GoDaddy. In order to get values for
// all {Account, Profile} pairs run:
//
// const cts = utui.data.customizations;
// Object.keys(cts).forEach(cid => {
//   if (cts[cid].catNameIdLookup) {
//     copy(cts[cid].catNameIdLookup.replace(/\&quot\;/g, '"'));
//   }
// });
//
// from the "Extensions" page inside of TealiumIQ
//
export const OPT_OUT_CATS = {
  advertising: 2,
  analytics: 9,
  support: 11
};

export const PRIVACY_POLICY_URL = process.env.PRIVACY_POLICY_URL;
export const RESELLER_PRIVACY_POLICY_URL = process.env.RESELLER_PRIVACY_POLICY_URL;
export {
  UTAG_URL,
  UTAG_DEFAULT_RESELLER_URL
};

export const UTAG_RESELLER_URLS = {
  dev: 'https://tags.tiqcdn.com/utag/gpl/main/dev/utag.js',
  test: 'https://tags.tiqcdn.com/utag/gpl/main/qa/utag.js',
  prod: 'https://tags.tiqcdn.com/utag/gpl/main/prod/utag.js'
};


// This is the last "reset date" after which a new interaction
// is required in order to get fresh consent
//
// NOTE: When updating, be sure to set time to *AFTER* deploy
// with large enough window that relevant HTTP caches get
// invalidated

// GDPR Launch Date
// 2018-05-23 18:00:00 GMT
// 1527098400000
export const GDPR_DATE = 1527098400000;

// 2021-05-17 22:00:00 GMT
// 1621288800000
export const CONSENT_DATE = 1621288800000;
