module.exports = {
  privacyManager: {
    impression: 'gtp.privacy_manager.modal.default.impression',
    save: 'gtp.privacy_manager.save.button.click',
    dismiss: 'gtp.privacy_manager.dismiss.button.click',
    analytics: 'gtp.privacy_manager.categories.analytics.toggle.click',
    support: 'gtp.privacy_manager.categories.support.toggle.click',
    advertising: 'gtp.privacy_manager.categories.advertising.toggle.click'
  },
  explicitBanner: {
    impression: 'gtp.consent_banners.express.impression',
    accept: 'gtp.consent_banners.express.accept.button.click',
    dismiss: 'gtp.consent_banners.express.dismiss.button.click',
    moreInfo: 'gtp.consent_banners.express.more_information.button.click',
    manageSettings: 'gtp.consent_banners.express.manage_settings.button.click',
    decline: 'gtp.consent_banners.express.decline.button.click'
  },
  implicitNoticeBanner: {
    impression: 'gtp.consent_banners.implicit_notice.default.impression',
    dismiss: 'gtp.consent_banners.implicit_notice.dismiss.button.click',
    moreInfo: 'gtp.consent_banners.implicit_notice.more_information.button.click',
    manageSettings: 'gtp.consent_banners.implicit_notice.manage_settings.button.click'
  },
  implicitBanner: {
    impression: 'gtp.consent_banners.implicit.default.impression',
    close: 'gtp.consent_banners.implicit.close.button.click',
    moreInfo: 'gtp.consent_banners.implicit.more_information.button.click'
  }
};
