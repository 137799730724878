import React from 'react';
import PropTypes from 'prop-types';

import SiblingSet from '@ux/sibling-set';
/**
 * Render a banner with standard title & notice and
 * configurable choices buttons
 *
 * @param    {string}    notice            Notice the banner is displaying to customers
 * @param    {Function}  renderChoices     Render prop for choices to display
 * @param    {Function}  [renderLearnMore] Render prop for content after the title & notice
 * @param    {string}    title             Notice title for the banner
 * @param    {boolean}   visible           If false, no component returned
 * @returns  {Component} React component for the Banner to display
 */
export default function Banner({ notice, renderChoices, renderCrossIcon, renderLearnMore, title, visible }) {
  if (!visible) return null;

  return (
    <div id='privacy_widget'>
      <div className='alert alert-dismissable' role='alert' aria-label='privacyAlert'>
        <div className='pw_cancel'>
          { renderCrossIcon && renderCrossIcon() }
        </div>
        <div id='pw_banner'>
          <span className='font-primary-bold'>{ title } </span>
          { notice }
          { renderLearnMore && renderLearnMore() }
        </div>
        <div className='pw_buttons'>
          <SiblingSet split className='text-center' gap={ 'sm' }>
            { renderChoices() }
          </SiblingSet>
        </div>
      </div>
    </div>
  );
}

Banner.propTypes = {
  notice: PropTypes.string.isRequired,
  renderChoices: PropTypes.func.isRequired,
  renderCrossIcon: PropTypes.func,
  renderLearnMore: PropTypes.func,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired
};
