import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@ux/button';
import Banner from './banner.js';

/**
 * Create explicit banner view
 *
 * @param {Visitor} visitor Page viewer for which consents are updating
 * @param {object} eids Object of events name to send events to traffic client
 * @param {object} i18n The localization strings object
 * @param {function} onClose onClose banner module open privacy manager module
 * @param {string} privacyPolicyUrl The URL to get more details about privacy policy
 * @returns {Component} The explicit banner module
 * @public
 */
export default function ExplicitBanner({ visitor, eids, i18n, onClose, privacyPolicyUrl }) {
  const [visible, setVisible] = useState(true);

  const onClickAccept = () => {
    visitor.recordInteraction({
      eid: eids.accept,
      utag: true,
      consents: 'enable'
    });

    setVisible(false);
    onClose({ action: 'accept' });
  };

  const onClickDecline = () => {
    visitor.recordInteraction({
      eid: eids.decline,
      // We load utag on decline because Tealium will respect
      // OPTOUTMULTI and only load those tags which do not
      // require consent (specifically LiveEngage).
      utag: true,
      consents: 'disable'
    });

    setVisible(false);
    onClose({ action: 'decline' });
  };

  const onClickManageSettings = () => {
    visitor.recordInteraction({ eid: eids.manageSettings });

    setVisible(false);
    onClose({ action: 'manage' });
  };

  const renderChoices = () => {
    return <>
      <Button
        id='pw_manage'
        onClick={ onClickManageSettings }
        className='btn-link ux-btn-set-item'
        design='link'
        type='button'
        text={ i18n['banners.manageSettings'] } />
      <Button
        id='pw_decline'
        onClick={ onClickDecline }
        className='btn-link ux-btn-set-item'
        design='link'
        type='button'
        text={ i18n['banners.decline'] } />
      <Button
        id='pw_accept'
        onClick={ onClickAccept }
        className='btn-filled ux-btn-set-item'
        design='primary'
        text={ i18n['banners.accept'] } />
    </>;
  };

  const renderLearnMore = () => {
    return <>
      {' '}
      <Button id='pw_info'
        href={ privacyPolicyUrl }
        external={ true }
        design='inline'
        size='small'
        text={ i18n['banners.privacyPolicy'] }
        data-eid={ eids.moreInfo } />
    </>;
  };

  const renderCrossIcon = () => {
    return <Button
      onClick={ onClickDecline }
      aria-label='Close modal'
      className='modal-close'
      text='x'/>;
  };

  return <Banner
    title={ i18n['banners.title'] }
    notice={ i18n['banners.explicit.description'] }
    visible={ visible }
    renderChoices={ renderChoices }
    renderCrossIcon={ renderCrossIcon }
    renderLearnMore={ renderLearnMore } />;
}

ExplicitBanner.propTypes = {
  visitor: PropTypes.object.isRequired,
  eids: PropTypes.objectOf(PropTypes.string).isRequired,
  i18n: PropTypes.objectOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  privacyPolicyUrl: PropTypes.string.isRequired
};
