import Cookie from './cookie.js';

import { GDPR_DATE, CONSENT_DATE } from '../config.js';
export default class PwInteraction extends Cookie {
  constructor({ domain } = {}) {
    super({ name: 'pwinteraction', domain });
  }

  /**
   * Suppresses Banners from appearing going forward by writing
   * a time value that will be evaluated against future interaction
   * times.
   *
   * @public
   */
  record() {
    this.write({ value: this.getCookieExpiry(0) });
  }

  /**
   * Validate cookie value
   *
   * @param {string} market the market for which to check consent cutoff date
   * @returns {boolean} Compare last interaction date
   * @public
   */
  isRecent(market) {
    const contents = this.read();
    // old / missing cookie, must get new interaction
    if (!contents || contents === 'yes' || typeof contents !== 'string') {
      return false;
    }

    const lastInteractionDate = Date.parse(decodeURI(contents));
    if (Number.isNaN(lastInteractionDate)) {
      return false;
    }

    return market === 'en-GB' || market === 'en-IE' ?
      lastInteractionDate >= CONSENT_DATE :
      lastInteractionDate >= GDPR_DATE;
  }
}
