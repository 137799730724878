import Cookie from './cookie.js';

/**
 * @typedef {object} optOutCategories
 * @prop {{[categoryId: string]: string}} Implements activeCatIdMap Active categories API from Tealium
 */

/**
 * Regular expression that determines whether a given
 * string is a value "cvalue" for OPTOUTMULTI cookie.
 * e.g. 0:0|c2:0|c9:0|c11:0
 *
 * @type {RegExp}
 */
const isCValue = /(^0:)|(^[Cc][0-9]{1,3})/;

export default class OptOutMulti extends Cookie {
  constructor({ optOutCats, domain, context }) {
    super({ name: 'OPTOUTMULTI', domain });

    this.context = context;
    this.optOutCats = optOutCats;
    /**
     * @property dictionary of category ids to numeric boolean for consent
     * @type {Record<number, 0 | 1>}
     */
    this.consents = Object.keys(optOutCats).reduce((acc, category) => {
      acc[category] = 1;
      return acc;
    }, {});
  }

  /**
   * Override parent read to also convert value into the this.consents object
   *
   * @inheritdoc
   */
  read() {
    const value = super.read();
    this.setFromStringValue(value);
    return value;
  }

  /**
   * Used to set OPTOUTMULTI cookie on the current base domain in
   * exactly the way that Tealium is expecting to read it
   *
   * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
   * Using `encodeUri` NON-STANDARD – see citation below that
   * TEALIUM DOES DO THIS inside of the Consent Manager Tag template
   * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
   *
   *     getCookieValues : function(){
   *         var values = {},
   *             rcd    = (function(){
   *                 var value = "; " + document.cookie;
   *                 var parts = value.split("; " + utag.gdpr.cookieNS + "=");
   *                 if (parts.length == 2) return utag.ut.decode(parts.pop().split(";").shift());
   *             }()),
   *             cd     = utag.gdpr.typeOf(rcd) === "string" ? rcd : "";
   *
   *         if (utag.data && cd) {
   *             utag.data["cp." + utag.gdpr.cookieNS] = cd;
   *         }
   *
   *         if (cd) {
   *             var i,
   *                 optOut,
   *                 optOutData = decodeURI(cd).split("|");
   *             for (i = 0; i < optOutData.length; i++){
   *                 optOut = optOutData[i].split(":");
   *                 values[optOut[0]] = optOut[1];
   *             }
   *         }
   *         utag.gdpr.values = values;
   *         return values;
   *     },
   *
   * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
   * If we do not do this then Tealium will be
   * UNABLE TO READ the values that we set
   * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
   *
   *     node -pe "decodeURI(encodeURIComponent('0:0|c2:0|c9:0|c11:0'))"
   *     0%3A0|c2%3A0|c9%3A0|c11%3A0'
   *
   * @param {Object} opts Options
   * @param {string} [opts.value]   The value of the cookie to set
   * @public
   */
  write({ value }) {
    this.value = value;

    let cookie = `${encodeURI(this.name)}=${encodeURI(value)}`;

    const attrs = this.attributes;
    if ('expires' in attrs) cookie += '; expires=' + attrs.expires;
    if ('path' in attrs) cookie += '; path=' + attrs.path;
    if ('domain' in attrs) cookie += '; domain=' + attrs.domain;

    //
    // Which is why we must break encapsulation to call
    // `document.cookie = cookie` within OptOutMulti
    //
    const doc = this.context.document || {};
    doc.cookie = cookie;
  }

  /**
   * Remove anything from the cookie that does not
   * match a valid category name
   *
   * @returns {boolean} Value indicating if repairs were attempted
   * @public
   */
  repair() {
    if (!this.value) super.read();

    const hasValue = !!this.value;
    if (!hasValue) return false;

    const optOutValues = this.value
      .split('|')
      .filter(cv => isCValue.test(cv));

    //
    // Delete original cookie before writing new.
    // This **MUST** be done due to Safari's limitations
    //
    this.remove('OPTOUTMULTI');
    this.write({ value: optOutValues.join('|') });
    this.setFromStringValue(this.value);
    return true;
  }

  /**
   * Converts a string cookie value into a this.consents object. Will merge
   * the resulting object into this.consents
   *
   * @param {string} value OPTOUTMULTI string value e.g. 0:0|c2:0|...
   */
  setFromStringValue(value) {
    if (!value) return;

    const optOutValues = value
      .split('|')
      .filter(cv => isCValue.test(cv) && cv !== '0:0')
      .map((cval) => {
        const [rawCat, optout] = cval.split(':');
        const cat = rawCat.toLowerCase().slice(1); // Remove leading c
        return { [cat]: Number(optout) };
      })
      .reduce((acc, curr) => ({ ...acc, ...curr }), {});

    const consents = Object.keys(this.optOutCats).reduce((acc, category) => {
      acc[category] = optOutValues[this.optOutCats[category]];
      return acc;
    }, {});

    this.consents = {
      ...this.consents,
      ...consents
    };
  }

  /**
   * Serializes the target optOutCats into a valid cookie
   * string value with all categories default disabled
   *
   * @returns {string} Cookie string value
   */
  toStringValue() {
    const { optOutCats, consents } = this;
    return Object.keys(consents).reduce((acc, category) => {
      return `${acc}|c${optOutCats[category]}:${consents[category]}`;
    }, '0:0');
  }

  /**
   * Writes OPTOUTMULTI value for the opt-out categories associated
   * with this instance for a given action to {enable,disable} all tracking
   *
   * @param {"enable"|"disable"|string} action What state transition to take
   * @public
   */
  setOptOutAction(action) {
    this.setConsents(action);
    this.write({ value: this.toStringValue() });
  }

  /**
   * Updates the consents value for the target value. One of:
   *
   *   ['enable', 'disable', { <category>: <0 => enable, 1 => disable> }]
   *
   * @param  {string|Record<number, 0 | 1>} target New target to set consents for this instance with
   */
  setConsents(target) {
    if (typeof target === 'string') {
      Object.keys(this.consents).forEach(category => {
        this.consents[category] = target === 'enable' ? 0 : 1;
      });
    } else if (typeof target === 'object') {
      this.consents = {
        ...this.consents,
        ...target
      };
    }
  }
}
